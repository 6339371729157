import React, { Component } from 'react'
import {graphql} from "gatsby";
import {fluidImage, fluidImageTablet, fluidImageMobile} from 'components/Image'
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Hero from "../components/Hero"
import AboutBlock from "../components/AboutBlock"

class IndexPage extends Component {

    
    render() {
        const props = this.props;
        return(
            <Layout>
                <SEO title="About Us" description="Michael Rose" />
               <Hero 
                headerImage={props.data.headerIndexImage.childImageSharp.fluid}
                headerImageTablet={props.data.headerIndexImageTablet.childImageSharp.fluid}
                headerImageMobile={props.data.headerIndexImageMobile.childImageSharp.fluid}
                headerText="About us"
                display="none"
                displayLogo="none"/> 
                <AboutBlock />
               
            </Layout>
        )
    }
}
export default IndexPage


export const IndexPageQuery = graphql`
query {
    headerIndexImage: file(relativePath: { eq: "header/header-about-1920.jpg" }) {
        ...fluidImage
    }
    headerIndexImageTablet: file(relativePath: { eq: "header/header-about-768.jpg" }) {
        ...fluidImageTablet
    }
    headerIndexImageMobile: file(relativePath: { eq: "header/header-about-576.jpg" }) {
        ...fluidImageMobile
    }
}`