import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import image1 from 'images/MICHAEL-ROSE2.jpg'
import image2 from 'images/morgan.jpg'
// import { Link } from 'gatsby'
// import MainSlider from 'components/MainSlider'


class MainCopy extends Component {

  render() {
    // const { images } = this.state
    return (
      
    
    <Container fluid className="bg-black py-5 about">
        <div className="content">
            <Row>

                <Col sm={12} xl={3} className="mb-3 image-wrapper">
                   <img src={image1} alt="M" />
                </Col>
                <Col sm={12} xl={7} className="mb-3">

                    <h2 className="mb-3 text-thick">Michael Rose</h2>
                    <h3>Michael Rose graduated from King Alfred’s College (Winchester University) with a degree in English and Drama. His first job was as an English and Drama Teacher in a school in Winchester. He first started producing shows in 1984.</h3>

                   <p>Productions include UK and European premieres Maltby & Shire’s <span className="text-italic">Baby</span> which won the Manchester Evening News Award, <span className="text-italic">Driving Miss Daisy</span> nominated for best touring show in the TMA Martini Rossi Awards, The European Premiere of <span className="text-italic">Closer Than Ever, The Great Gatsby, The Cemetery Club, The Lion In Winter, The Surprise Party, Five Blue Haired Ladies Sitting On A Green Park Bench</span> and two thrillers, <span className="text-italic">Nightmare</span> and <span className="text-italic">Nightfright.</span> </p>
                   <p>Transfers from London’s West End include <span className="text-italic">Steel Magnolias</span> and Olivier Award winning productions of <span className="text-italic">Hot Shoe Shuffle</span> and <span className="text-italic">Tap Dogs.</span> </p>
                   <p>West End of London includes Duke Ellington’s <span className="text-italic">Sophisticated Ladies</span> at the Gielgud, Pam Gem’s <span className="text-italic">Marlene</span> at the Lyric nominated for two Olivier Awards and <span className="text-italic">Sweet Charity</span> at the Victoria Palace. </p>
                   <p>The longest running musical at the London Palladium, the smash hit musical <span className="text-italic">Chitty Chitty Bang Bang</span>, nominated for 3 Olivier Awards and winner of Best Musical in the 2002 Variety Club Awards. Chitty also holds the Guinness Book of Records for the most expensive stage prop ever – the car! </p>
                   <p><span className="text-italic">Footloose</span> at the Novello Theatre, <span className="text-italic">Chariots of Fire</span> and <span className="text-italic">Strangers On A Train</span> at the Gielgud. National tours include; <span className="text-italic">Jesus Christ Superstar, The Boyfriend, Oliver!, Peter Pan, Bell Book</span> and <span className="text-italic">Candle, Dames At Sea, The Pied Piper, The Wizard Of Oz. Santa Claus</span> - The Musical at the Mayflower Southampton, Liverpool Empire and Alexandra Theatre Birmingham. </p>
                   <p>2005 saw <span className="text-italic">Chitty Chitty Bang Bang</span> on Broadway nominated for 5 Tony Awards. Also a three year UK tour (winning Best Family Show, Manchester Evening News Awards). And her debut at the Esplanade Theatre, Singapore. </p>
                   <p>The UK premiere of Irving Berlin’s <span className="text-italic">White Christmas</span> with seasons at Plymouth, Southampton, Edinburgh, Wales Millennium Centre Cardiff, Sunderland, Liverpool, Dublin and Salford. A brand new production of the RSC version of <span className="text-italic">The Wizard of Oz</span> at the Mayflower Southampton which then embarked upon a tour of the USA.</p> 
                   <p>Michael produced a new version of <span className="text-italic">Chitty Chitty Bang Bang</span> which embarked upon a more extensive tour of the British Isles until 2010. </p>
                   <p><span className="text-italic">A Steady Rain</span> starring Daniel Craig and Hugh Jackman on Broadway, <span className="text-italic">Catwalk Confidential</span> at The Arts Theatre, London, <span className="text-italic">Santa Claus & The Return of Jack Frost</span> at The Mayflower, Southampton.</p>
                   <p><span className="text-italic">Aladdin</span> Southampton Mayflower and The Theatre at The O2 starring Paul O’Grady returning to the guise of Lily Savage as Widow Twankey. </p>
                   <p>A new musical version of <span className="text-italic">Peter Pan</span> premiering at the Grand Canal Theatre in Dublin followed by a Christmas season at The Mayflower, Southampton. </p>
                   <p>A sell out season of Irving Berlin’s <span className="text-italic">White Christmas</span> The Musical at the Dominion Theatre, London and the UK premiere of <span className="text-italic">Elf The Musical</span> with record breaking seasons at Plymouth, Dublin, Salford and the Dominion Theatre, London. </p>
                    <p>Michael has been developing a new musical, <span className="text-italic">Sleepless</span>, based on the Tom Hanks Meg Ryan movie <span className="text-italic">Sleepless</span> In Seattle. The UK premiere of <span className="text-italic">BIG the musical</span> – Plymouth Theatre Royal, Dublin Bord Gais and Dominion Theatre London. <span className="text-italic">Sleepless the musical</span> Troubadour Theatre Wembley Park London.</p>

                </Col>
              </Row>

              <Row>
                <Col sm={12} xl={3} className="mb-3 image-wrapper">
                    <img src={image2} alt="M"/>
                </Col>
                <Col sm={12} xl={7} className="mb-3">

                    <h2 className="mb-3 text-thick">David Morgan Young</h2>
                    <h3>From the age of six to fourteen David trained in Ballroom and Latin American Dance. His earliest public appearance was in 1962 doing a solo performance of the Twist…… dressed as an Elf.</h3>
                    <p>Early training was taken in Bournemouth at The Tilbury School of Ballroom Dancing with the late and great Nora and Sidney Tilbury, The Stage Door School of Dance and Drama under the direction of Maureen Headford and Capricorn Youth Theatre in New Milton under the direction of Helen Holden-Dye. </p>
                    <p>Offered a scholarship from the late Doreen Bird in 1977 he graduated from the Bird College of Performing Arts in 1980 and performed in the West End and throughout the UK. </p>
                    <p>As a choreographer credits include <span className="text-italic">High Society</span> at West Yorkshire Playhouse, <span className="text-italic">Treasure Island</span> at Theatr Clwyd, <span className="text-italic">Snap Out Of It!,</span> a play on mental health, Maltby and Shire’s <span className="text-italic">Closer Than Ever</span> at the Library Theatre Manchester and the first Festival of Musicals at Buxton Opera House, Duke Ellington’s <span className="text-italic">Sophisticated Ladies</span> national tour and Gielgud Theatre London. National tours include <span className="text-italic">Dames At Sea, The Wizard of Oz, Oh What A Lovely War, Godspell, Jesus Christ Superstar, The Pied Piper, The Adventures Of Mr Toad, The Boyfriend, You’re A Good Man Charlie Brown</span> and <span className="text-italic">Cora</span> La Grand Horizontale which opened the first New York International Theatre Festival at <span className="text-italic">Suny</span> on Long Island. </p>
                    <p><span className="text-italic">The Bafta Award Winning, Your Mother Wouldn't Like It</span> for Central TV (3 series) and <span className="text-italic">The Medal Song,</span> a promo video for Boy George. </p>
                    <p>He also choreographed the 2009/10 UK and Ireland touring production of <span className="text-italic">Chitty Chitty Bang Bang and Monkee Busines,</span> a new musical with songs from the iconic 60’s pop group The Monkees.</p>
                    <p>Directorial credits include <span className="text-italic">Tomfoolery, Peter Pan</span> (three times with three wonderful ‘Hooks’ Matthew Kelly, Ron Moody and Ben Richards), <span className="text-italic">Beauty & The Beast, Aladdin, Santa Claus</span> and the Return of <span className="text-italic">Jack Frost</span> and <span className="text-italic">Cinderella</span> at the Mayflower in Southampton. </p>
                    <p>Morgan directed <span className="text-italic">Me & My Girl</span> at Lincoln’s Theatre Royal and the 21st Anniversary Production, <span className="text-italic">Singin' In The Rain</span> also at Lincoln. </p>
                    <p>UK director for ten years of Irving Berlin’s <span className="text-italic">White Christmas</span> with record breaking seasons at Plymouth Theatre Royal, Edinburgh Playhouse, Wales Millennium Centre Cardiff, Lowry in Salford, Mayflower Southampton, Grand Canal Theatre Dublin, Liverpool Empire and the Empire Sunderland. </p>
                    <p><span className="text-italic">White Christmas</span> also spent a record breaking season at the Dominion Theatre London. <span className="text-italic">Aladdin</span> at The Mayflower, Southampton starring Lily Savage (by kind permission of Paul O'Grady) in the role of Widow Twankey which transferred to The Theatre at The O2 for Christmas 2013.</p>
                     <p>A new musical version of <span className="text-italic">Peter Pan</span> - at the Grand Canal Theatre Dublin followed by a Christmas season at The Mayflower, Southampton. </p>
                     <p>A workshop presentation of <span className="text-italic">Sleepless</span>, a new musical based on the movie <span className="text-italic">Sleepless In Seattle.</span></p>
                    <p>Morgan directed and choreographed <span className="text-italic">Elf The Musical</span> at Plymouth Theatre Royal, Bord Gais Energy Theatre Dublin, a sell out season at The Dominion Theatre London and a record breaking season at the Lowry Theatre Salford. <span className="text-italic">BIG the musical</span> – Plymouth Theatre Royal, Dublin Bord Gais and Dominion Theatre London. <span className="text-italic">Sleepless the musical</span> Troubadour Theatre Wembley Park London.</p>
                </Col>

            </Row>
        </div>
        
    </Container>
    
    )
  }
}

export default MainCopy
